<template>
  <div class="main-layout">
    <div id="main">
      <div id="top-logo">
        <img src="/images/physi.jpg" />
      </div>
      <h1>Privacy policy</h1>
      <div id="content">
        <div data-custom-class="body">
          <p>
            <strong> PRIVACY NOTICE </strong>
          </p>
          <p><strong> Last updated January 12, 2023 </strong></p>
          <p>
            This privacy notice for Physi Solutions Kft. (doing business as
            Physi) ("<strong>Physi</strong>," "<strong>we</strong>,"
            "<strong>us</strong>," or "<strong>our</strong>"), describes how and
            why we might collect, store, use, and/or share
            ("<strong>process</strong>") your information when you use our
            services ("<strong>Services</strong>"), such as when you:
          </p>
          <ul>
            <li>
              Visit our website at
              <a href="https://urbannatureexplorer.com" target="_blank"
                >https://urbannatureexplorer.com</a
              >, or any website of ours that links to this privacy notice
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <p>
            <strong>Questions or concerns?</strong>
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you still have any
            questions or concerns, please contact us at hello@physi.earth.
          </p>
          <p><strong>SUMMARY OF KEY POINTS</strong></p>
          <p>
            <strong
              ><em
                >This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our table
                of contents below to find the section you are looking for. You
                can also click
              </em></strong
            >
            <a href="#toc"
              ><strong><em>here</em></strong></a
            >
            <strong><em> to go directly to our table of contents.</em></strong>
          </p>
          <p>
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with Physi and the
            Services, the choices you make, and the products and features you
            use. Click <a href="#personalinfo">here</a> to learn more.
          </p>
          <p>
            <strong>Do we process any sensitive personal information?</strong>
            We do not process sensitive personal information.
          </p>
          <p>
            <strong>Do we receive any information from third parties?</strong>
            We do not receive any information from third parties.
          </p>
          <p>
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Click
            <a href="#infouse">here</a> to learn more.
          </p>
          <p>
            <strong
              >In what situations and with which parties do we share personal
              information?</strong
            >
            We may share information in specific situations and with specific
            third parties. Click <a href="#whoshare">here</a> to learn more.
          </p>
          <p>
            <strong>How do we keep your information safe?</strong> We have
            organizational and technical processes and procedures in place to
            protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information. Click
            <a href="#infosafe">here</a> to learn more.
          </p>
          <p>
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information. Click
            <a href="#privacyrights">here</a> to learn more.
          </p>
          <p>
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by filling out our data subject request form
            available here: tech-support@physi.earth, or by contacting us. We
            will consider and act upon any request in accordance with applicable
            data protection laws.
          </p>
          <p>
            Want to learn more about what Physi does with any information we
            collect? Click <a href="#toc">here</a> to review the notice in full.
          </p>
          <p id="toc"><strong>TABLE OF CONTENTS</strong></p>
          <p><a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a></p>
          <p><a href="#infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</a></p>
          <p>
            <a href="#legalbases"
              >3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?</a
            >
          </p>
          <p>
            <a href="#whoshare"
              >4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a
            >
          </p>
          <p>
            <a href="#inforetain">5. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
          </p>
          <p>
            <a href="#infosafe">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
          </p>
          <p>
            <a href="#infominors">7. DO WE COLLECT INFORMATION FROM MINORS?</a>
          </p>
          <p><a href="#privacyrights">8. WHAT ARE YOUR PRIVACY RIGHTS?</a></p>
          <p><a href="#DNT">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a></p>
          <p>
            <a href="#policyupdates">10. DO WE MAKE UPDATES TO THIS NOTICE?</a>
          </p>
          <p>
            <a href="#contact">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
          </p>
          <p>
            <a href="#request"
              >12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
              FROM YOU?</a
            >
          </p>
          <p id="infocollect">
            <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
          </p>
          <p id="personalinfo">
            <strong>Personal information you disclose to us</strong>
          </p>
          <p>
            <strong><em>In Short:</em></strong
            ><strong><em> </em></strong
            ><em>We collect personal information that you provide to us.</em>
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p>
            <strong>Personal Information Provided by You.</strong> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
          </p>
          <ul>
            <li>email addresses</li>
            <li>usernames</li>
            <li>passwords</li>
          </ul>
          <p id="sensitiveinfo">
            <strong>Sensitive Information.</strong> We do not process sensitive
            information.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <p id="infouse">
            <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
          </p>
          <p>
            <strong><em>In Short: </em></strong
            ><em
              >We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent.</em
            >
          </p>
          <p>
            <strong
              >We process your personal information for a variety of reasons,
              depending on how you interact with our Services,
              including:</strong
            >
          </p>
          <ul>
            <li>
              <strong
                >To facilitate account creation and authentication and otherwise
                manage user accounts. </strong
              >We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </li>
            <li>
              <strong
                >To deliver and facilitate delivery of services to the user. </strong
              >We may process your information to provide you with the requested
              service.
            </li>
            <li>
              <strong
                >To save or protect an individual's vital interest.</strong
              >
              We may process your information when necessary to save or protect
              an individual’s vital interest, such as to prevent harm.
            </li>
          </ul>
          <p id="legalbases">
            <strong
              >3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
              INFORMATION?</strong
            >
          </p>
          <p>
            <em
              ><strong>In Short: </strong>We only process your personal
              information when we believe it is necessary and we have a valid
              legal reason (i.e., legal basis) to do so under applicable law,
              like with your consent, to comply with laws, to provide you with
              services to enter into or fulfill our contractual obligations, to
              protect your rights, or to fulfill our legitimate business
              interests.</em
            >
          </p>
          <p>
            <em
              ><strong
                ><u
                  >If you are located in the EU or UK, this section applies to
                  you.</u
                ></strong
              ></em
            >
          </p>
          <p>
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>
          <ul>
            <li>
              <strong>Consent. </strong>We may process your information if you
              have given us permission (i.e., consent) to use your personal
              information for a specific purpose. You can withdraw your consent
              at any time. Click <a href="#withdrawconsent">here</a> to learn
              more.
            </li>
            <li>
              <strong>Performance of a Contract.</strong> We may process your
              personal information when we believe it is necessary to fulfill
              our contractual obligations to you, including providing our
              Services or at your request prior to entering into a contract with
              you.
            </li>
            <li>
              <strong>Legal Obligations.</strong> We may process your
              information where we believe it is necessary for compliance with
              our legal obligations, such as to cooperate with a law enforcement
              body or regulatory agency, exercise or defend our legal rights, or
              disclose your information as evidence in litigation in which we
              are involved.<br />
            </li>
            <li>
              <strong>Vital Interests.</strong> We may process your information
              where we believe it is necessary to protect your vital interests
              or the vital interests of a third party, such as situations
              involving potential threats to the safety of any person.
            </li>
          </ul>
          <p id="whoshare">
            <strong
              >4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
              INFORMATION?</strong
            >
          </p>
          <p>
            <strong><em>In Short:</em></strong
            ><em>
              We may share information in specific situations described in this
              section and/or with the following third parties.</em
            >
          </p>
          <p>
            We may need to share your personal information in the following
            situations:
          </p>
          <ul>
            <li>
              <strong>Business Transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
          </ul>
          <p id="inforetain">
            <strong>5. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
          </p>
          <p>
            <strong><em>In Short: </em></strong
            ><em
              >We keep your information for as long as necessary to fulfill the
              purposes outlined in this privacy notice unless otherwise required
              by law.</em
            >
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
          <p id="infosafe">
            <strong>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
          </p>
          <p>
            <strong><em>In Short: </em></strong
            ><em
              >We aim to protect your personal information through a system of
              organizational and technical security measures.</em
            >
          </p>
          <p>
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
          <p id="infominors">
            <strong>7. DO WE COLLECT INFORMATION FROM MINORS?</strong>
          </p>
          <p>
            <strong><em>In Short:</em></strong
            ><em>
              We do not knowingly collect data from or market to children under
              18 years of age.</em
            >
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at tech-support@physi.earth.
          </p>
          <p id="privacyrights">
            <strong>8. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
          </p>
          <p>
            <strong><em>In Short:</em></strong
            ><em
              >In some regions, such as the European Economic Area (EEA), United
              Kingdom (UK), and Canada, you have rights that allow you greater
              access to and control over your personal information. You may
              review, change, or terminate your account at any time.</em
            >
          </p>
          <p>
            In some regions (like the EEA, UK, and Canada), you have certain
            rights under applicable data protection laws. These may include the
            right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section "<a href="#contact"
              >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a
            >" below.
          </p>
          <p>
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p>
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your local data protection supervisory
            authority. You can find their contact details here:
            <a
              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
              rel="noopener noreferrer"
              target="_blank"
              >https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a
            >.
          </p>
          <p>
            If you are located in Switzerland, the contact details for the data
            protection authorities are available here:
            <a
              href="https://www.edoeb.admin.ch/edoeb/en/home.html"
              rel="noopener noreferrer"
              target="_blank"
              >https://www.edoeb.admin.ch/edoeb/en/home.html</a
            >.
          </p>
          <p id="withdrawconsent">
            <strong><u>Withdrawing your consent:</u></strong> If we are relying
            on your consent to process your personal information, which may be
            express and/or implied consent depending on the applicable law, you
            have the right to withdraw your consent at any time. You can
            withdraw your consent at any time by contacting us by using the
            contact details provided in the section "<a href="#contact"
              >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a
            >" below.
          </p>
          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <p><strong>Account Information</strong></p>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul>
            <li>Contact us using the contact information provided.</li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at tech-support@physi.earth.
          </p>
          <p id="DNT"><strong>9. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
          <p id="policyupdates">
            <strong>10. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
          </p>
          <p>
            <em
              ><strong>In Short: </strong>Yes, we will update this notice as
              necessary to stay compliant with relevant laws.</em
            >
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <p id="contact">
            <strong>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
          </p>
          <p>
            If you have questions or comments about this notice, you may email
            us at tech-support@physi.earth
          </p>
          <p id="request">
            <strong
              >12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
              FROM YOU?</strong
            >
          </p>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please visit:
            tech-support@physi.earth.
          </p>
          <p
            style="
              color: #595959;
              font-size: 14px;
              font-family: Arial;
              padding-top: 16px;
            "
          >
            This privacy policy was created using Termly's
            <a
              style="color: rgb(48, 48, 241) !important"
              href="https://termly.io/products/privacy-policy-generator/"
              >Privacy Policy Generator</a
            >.
          </p>
        </div>
      </div>
    </div>

    <main-layout />
  </div>
</template>
<script>
import MainLayout from "../components/MainLayout";
export default {
  components: { MainLayout },
};
</script>
<style lang="scss" scoped>
#main {
  width: 960px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 165px;
}
h1 {
  margin-top: 15px;
  margin-bottom: 75px;
}
#app #content {
  padding: 0;
  text-align: justify;
}
p {
  margin-top: 0;
}
#about-image {
  float: right;
}
#top-logo {
  text-align: center;
  img {
    height: 75px;
  }
}

ul {
  list-style-type: square;
}
ul > li > ul {
  list-style-type: circle;
}
ul > li > ul > li > ul {
  list-style-type: square;
}
ol li {
  font-family: Arial;
}
</style>
